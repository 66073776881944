import { AssessmentEnum } from "app-types";
import { FC } from "react";
import { Pill, PillColorsEnum, SizesEnum } from "ui";

export const scoreToDetails: {
  [key: number]: { color: PillColorsEnum; text: string };
} = {
  1: {
    color: PillColorsEnum.RED,
    text: AssessmentEnum.VERY_POOR,
  },
  2: {
    color: PillColorsEnum.YELLOW,
    text: AssessmentEnum.POOR,
  },
  3: {
    color: PillColorsEnum.GREY,
    text: AssessmentEnum.ACCEPTABLE,
  },
  4: {
    color: PillColorsEnum.LIME,
    text: AssessmentEnum.GOOD,
  },
  5: {
    color: PillColorsEnum.GREEN,
    text: AssessmentEnum.EXCELLENT,
  },
};

interface AssessmentScoreProps {
  score: number;
  size?: SizesEnum;
}

export const AssessmentScore: FC<AssessmentScoreProps> = ({
  score,
  size = SizesEnum.SMALL,
}) => {
  const scoreDetails = scoreToDetails[score];

  return (
    <Pill
      size={size}
      color={scoreDetails.color}
      label={`${score} · ${scoreDetails.text}`}
    />
  );
};
