import { IntegrationProvidersEnum, IntegrationSettings } from "../..";

export function deserializeIntegrationSettingsDb(
  provider: IntegrationProvidersEnum,
  settings: any
) {
  const dbSettings = typeof settings === "object" ? settings : {};

  switch (provider) {
    case IntegrationProvidersEnum.SALESFORCE:
      return {
        ...defaultSalesforceIntegrationSettings,
        ...dbSettings,
      } as SalesforceIntegrationSettings;
    case IntegrationProvidersEnum.SLACK:
      return {
        ...defaultSlackIntegrationSettings,
        ...dbSettings,
      } as SlackIntegrationSettings;
    case IntegrationProvidersEnum.FOUNTAIN:
      return {} as FountainIntegrationSettings;
  }
}

/*
 * Salesforce
 */

const defaultSalesforceIntegrationSettings: SalesforceIntegrationSettings = {
  instance_url: null,
  is_enabled: false,
};

export function isSalesforceIntegrationSettings(
  settings: IntegrationSettings
): settings is SalesforceIntegrationSettings {
  return (
    "instance_url" in settings &&
    (typeof settings.instance_url === "string" ||
      settings.instance_url === null) &&
    "is_enabled" in settings &&
    typeof settings.is_enabled === "boolean"
  );
}

export type SalesforceIntegrationSettings = {
  instance_url: string | null;
  is_enabled: boolean;
};

export type SalesforceIntegrationUpsert = {
  provider: IntegrationProvidersEnum.SALESFORCE;
  access_token: string;
  refresh_token: string;
  settings: SalesforceIntegrationSettings;
};

export type FountainIntegrationSettings = {};

export type FountainIntegrationUpsert = {
  provider: IntegrationProvidersEnum.FOUNTAIN;
  access_token: null;
  refresh_token: null;
  settings: FountainIntegrationSettings;
};

/*
 * Slack
 */

export const defaultSlackIntegrationSettings: SlackIntegrationSettings = {
  channel_id: null,
};

export function isSlackIntegrationSettings(
  settings: IntegrationSettings
): settings is SlackIntegrationSettings {
  return (
    "channel_id" in settings &&
    (typeof settings.channel_id === "string" || settings.channel_id === null)
  );
}

export function serializeSlackAPIChannelsResponse(
  channels: any
): SlackChannel[] {
  return channels
    .filter((c: any) => c.is_channel) // Want to filter out groups.
    .map((c: any) => ({
      id: c.id,
      name: c.name,
    }));
}

export type SlackChannel = {
  id: string;
  name: string;
};

export type SlackIntegrationSettings = {
  channel_id: string | null;
};

export type SlackIntegrationUpsert = {
  provider: IntegrationProvidersEnum.SLACK;
  access_token: string;
  settings: SlackIntegrationSettings;
};
