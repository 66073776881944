export enum AssessmentEnum {
  EXCELLENT = "Excellent",
  GOOD = "Good",
  ACCEPTABLE = "Acceptable",
  POOR = "Poor",
  VERY_POOR = "Very Poor",
}

export const assessmentScoreToLabelText = {
  [5]: AssessmentEnum.EXCELLENT,
  [4]: AssessmentEnum.GOOD,
  [3]: AssessmentEnum.ACCEPTABLE,
  [2]: AssessmentEnum.POOR,
  [1]: AssessmentEnum.VERY_POOR,
};
